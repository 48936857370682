import React from 'react';
import { Link } from 'gatsby';

import Container from 'components/Container';

const Header = () => {
  return (
    <header className="mt-6">
      <Container>
        <div className="extra-large-wrapper">
          <div className="flex justify-between items-center border-t border-b py-4">
            <Link to="/" className="font-serif text-4xl sm:w-full">Carly Bartel</Link>
            <ul className="main-nav flex flex-row w-full text-right justify-end">
              <li className="">
                <Link to="/" className="border hover:bg-green rounded-full font-mono duration-200 py-2 px-3 sm:px-6 block text-xs" activeClassName="active" ><span>01 </span>Home</Link>
              </li>
              <li className="ml-2 sm:ml-4">
                <Link to="/about" className="border hover:bg-green rounded-full font-mono duration-200 py-2 px-3 sm:px-6 block text-xs" activeClassName="active"><span className="">02 </span>About</Link>
              </li>
            </ul>
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Header;